var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(
      _vm.$route.name !== 'Login' &&
      _vm.$route.name !== 'LoginEucerin' &&
      _vm.$route.name !== 'LoginEucerinTest' &&
      _vm.$route.name !== 'Register' &&
      _vm.$route.name !== 'Home' &&
      _vm.$route.name !== 'AdminLogin' &&
      _vm.$route.name !== 'AdminMessageScreen' &&
      _vm.$route.name !== 'LiveSessions'
    )?_c('a-page-header',{staticStyle:{"border":"1px solid rgb(235, 237, 240)"},on:{"back":_vm.goBack}},[_c('span',{attrs:{"slot":"backIcon"},slot:"backIcon"},[(_vm.$route.name !== 'Sessions')?_c('a-icon',{attrs:{"slot":"backIcon","type":"left"},slot:"backIcon"}):_vm._e()],1),(
        _vm.$route.name !== 'AdminDashboard' &&
        _vm.$route.name !== 'AdminSession' &&
        _vm.$route.name !== 'AdminUsersLog' &&
        _vm.$route.name !== 'AdminUser'
      )?_c('div',{attrs:{"slot":"subTitle","id":"logo-wrapper"},slot:"subTitle"},[(!_vm.$store.state.headerLogo)?_c('a-icon',{staticClass:"logo-loading",attrs:{"type":"loading"}}):_c('img',{attrs:{"id":"logo","src":_vm.$store.state.headerLogo}})],1):_c('div',{attrs:{"slot":"subTitle","id":"logo-wrapper"},slot:"subTitle"},[_c('img',{attrs:{"id":"logo","src":_vm.livetubeLogo}})]),_c('div',{staticClass:"extra"},[_c('a-dropdown',{attrs:{"placement":"bottomCenter"}},[_c('a-button',{attrs:{"shape":"circle","icon":"user"}}),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[(_vm.user)?_c('a-menu-item',[_c('span',[_vm._v(_vm._s(_vm.user.name))])]):_vm._e(),_c('a-menu-item',[_c('span',{on:{"click":function($event){return _vm.logout()}}},[_vm._v("Logout")])])],1)],1)],1)]):_vm._e(),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }